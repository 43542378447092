.logoAnimation {
    display: block;
    color: white;
    text-transform: uppercase;
    height: 125px;
    width: 250px;
    cursor: pointer;
}
.logoAnimation .titleEnter {
    position: absolute;
    left: 50%;
    top: 50%;
    opacity: 0;
    color: rgba(255, 255, 255, 0.5);
    letter-spacing: 1px;
    font-size: 20px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-animation: opacity-2 2s 5s ease-in-out 1 normal forwards;
    animation: opacity-2 2s 5s ease-in-out 1 normal forwards;
    -webkit-transition: color 0.4s ease-in-out;
    transition: color 0.4s ease-in-out;
}
.logoAnimation .logo {
    background-image: url('./logo.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: block;
    height: 100%;
    opacity: 0;
    -webkit-animation: opacity 5s ease-in-out 1;
    animation: opacity 5s ease-in-out 1;
}
@keyframes opacity {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
.logoAnimation .ring {
    position: absolute;
    display: block;
    height: 122px;
    width: 122px;

    top: 2px;
    left: 119px;
    border-radius: 65px;
    -webkit-animation: rotate 8s 5s infinite linear, fadein-1 2s 5s ease-in normal forwards;
    animation: rotate 8s 5s infinite linear, fadein-1 2s 5s ease-in normal forwards;
}
.logoAnimation .ring.ring2 {
    left: 17px;
    -webkit-animation: rotate 6s 5s infinite linear, fadein-2 2s 5s ease-in normal forwards;
    animation: rotate 6s 5s infinite linear, fadein-2 2s 5s ease-in normal forwards;
}
@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    50% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes fadein-1 {
    0% {
        left: 119px;
    }
    100% {
        left: 65px;
    }
}
@keyframes fadein-2 {
    0% {
        left: 17px;
    }
    100% {
        left: 65px;
    }
}
.logoAnimation .ring::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 65px;
    padding: 1px;
    background: linear-gradient(45deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: source-out;
    mask-composite: exclude;
    transform: translateX(10px);
    transition: transform 0.4s ease-in-out;
    opacity: 0;
    -webkit-animation: opacity-2 2s 4s ease-in-out 1 normal forwards;
    animation: opacity-2 2s 4s ease-in-out 1 normal forwards;
}
@keyframes opacity-2 {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.logoAnimation .ring.ring2::before {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
}

.logoAnimation:hover .ring::before {
    -webkit-transform: translateX(0);
    transform: translateX(0);
}
.logoAnimation:hover .titleEnter {
    color: rgba(255, 255, 255, 1);
}
.buttonContainer{
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    height: 125px;
    width: 250px;
}
.skip {
    font-size: 10px;
    opacity: 0.5;
    color: white;
    letter-spacing: 0.5px;
    text-align: center;
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
}
