@import '../../../styles/variables.scss';
 
.form-check {
  &__label {
    color: #fff;
    font-family: $font-primary;
    font-size: 11px;
    line-height: 19px;
    font-weight: 500;

    a {
      color: #fff;
    }
  }
}
