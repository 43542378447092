@import 'src/styles/variables';

.settings {
  display: flex;
  flex-direction: column;
  background: $theme-primary;
  width: 100%;
  max-width: 600px;

  &-title {
    display: block;
    padding: 1rem;

    &__text {
      display: block;
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      font-size: 1.2rem;
    }
  }

  &-form {
    padding: .75rem;

    &__button {
      border-radius: .25rem;
      border: 0;
      padding: .35rem .5rem;
      color: $text-white;
      background: $theme-secondary;
      cursor: pointer;

      &:hover {
        background: lighten($theme-secondary, 25);
      }
    }
  }

  &-list {
    margin-bottom: 1rem;

    li {
      padding: .35rem;
      border-bottom: 1px solid $border;
    }

    &__label {
      display: flex;
      align-items: center;
      span {
        user-select: none;
        flex: 1;
      }
    }
  }
}

