.bar-button {
  position: absolute;
  z-index: 14;
  top: 12px;
  right: 4px;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;

  div > div:nth-of-type(2) {
    display: none !important;
  }

  @media only screen and (max-width: 600px) {
    right: 0;
    top: 0;
  }
}
