.Checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    height: 16px;
    // background-color: black;
    background: none;
    border: 1px solid white;
    border-radius: 5px;
    flex-shrink: 0;
    cursor: pointer;
    input {
        opacity: 0;
        position: absolute;
    }
}