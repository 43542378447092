@import 'src/styles/variables.scss';

.Container {
  display: flex;
  gap: 80px;
  position: relative;
  padding: 15px 118px;
  width: 100vw;
  height: 100%;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .Container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0;
    align-items: center;
    justify-content: center;
    height: auto;
  }
}
