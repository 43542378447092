@import '../../../styles/variables.scss';

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $theme-primary;
    border-radius: 34px;
    outline: none;
    width: 100%;
    height: 44px;
    max-width: 184px;
    margin-left: auto;
    margin-right: auto;
    background: none;
    color: $theme-primary;
    font-family: $font-primary;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    cursor: pointer;
    text-decoration: none;
}
