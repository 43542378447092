@import 'src/styles/variables.scss';

.Navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  width: 100%;
  height: 50px;
  max-width: 508px;

  .arrowBox {
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
      cursor: pointer;
    }
  
    span {
      font-family: $font-primary;
      color: $theme-primary;
      font-style: italic;
      font-size: 12px;
      line-height: 26px;
      margin: 0 10px;
      cursor: pointer;
    }
  }

  .snippetWrapper {
    text-align: center;

    span {
      font-family: $font-primary;
      color: $theme-primary;
      font-style: italic;
      font-size: 24px;
      line-height: 26px;
      font-weight: 400;
      margin: 0 30px;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 767px) {
  .Navigation {
    align-items: flex-end;
    height: 45px;
  }
}
