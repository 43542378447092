@import '../../../styles/variables.scss';

.login-form {
    &__submit {
        margin-top: 30px;
        background: none;
        border: 1px solid $theme-primary;
    }
}
.description {
    text-align: center;
    font-size: 11px;
    line-height: 15px;
    color: $text-white;
    margin-top: 5px;
}
