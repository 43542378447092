@import '../../../styles/variables.scss';

.backdrop {
    position: fixed;
    padding: 2rem 1rem;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.75);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    position: relative;
    // background-color: white;
    padding: 2rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    animation: slide-down 300ms ease-out forwards;
    font-family: $font-primary;
    overflow-y: auto;
    min-height: 60%;
    max-height: 90%;
    min-width: 60%;
    max-width: 100%;

    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    &::-webkit-scrollbar {
        height: 5px;
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $theme-secondary;
    }

    &__close {
        position: absolute;
        font-size: 24px;
        right: 0;
        top: 0;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
}

@keyframes slide-down {
    from {
        opacity: 0;
        transform: translateY(-3rem);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
