@import 'src/styles/variables.scss';

.Navigation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  user-select: none;
  max-width: 320px;
  width: 100%;
  height: 130px;

  svg {
    cursor: pointer;
  }

  span {
    font-family: $font-primary;
    color: $theme-primary;
    text-decoration: underline;
    font-style: italic;
    font-size: 12px;
    line-height: 26px;
    margin: 0 30px;
    cursor: pointer;
  }
}
@media (max-width: 1200px) {
  .Navigation {
    height: 65px;
  }
}
@media only screen and (max-width: 767px) {
  .Navigation {
    align-items: flex-end;
    height: 45px;
  }
}
