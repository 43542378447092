.container {
    width: 100%;
    // max-width: 420px;
    max-width: 278px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;

}
@media only screen and (max-width: 600px) {
    .container {
        max-width: 420px;
    }
}