// Theme
$theme-primary: #fff;
$theme-secondary: #000;

// Fonts
$text-white: #fff;
$text: #696f79;
$text-secondary: #8692a6;
$font-primary: 'Bodoni 72 Oldstyle', sans-serif;

// Elements
$divider: #f5f5f5;
$border: $text-secondary;
$timeline: #474747;

// Spinner
$spinner-primary: $theme-secondary;
$spinner-secondary: $text;

// Variants
$error: #fff;
$success: green;

//Nav
$nav-width: 300px;
