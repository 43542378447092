@import '../../../styles/variables.scss';

.input-password {
    &__wrapper {
        position: relative;
    }
    &__input {
        height: 40px;
        width: 100%;
        padding: 20px 70px 20px 25px;
        border-bottom: 1px solid $theme-primary;
        box-sizing: border-box;
        background: none;
        outline: none;
        font-family: $font-primary;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: $theme-primary;

        &::-webkit-contacts-auto-fill-button {
            visibility: hidden;
            display: none !important;
            pointer-events: none;
            position: absolute;
            right: 0;
        }
    }
    &__button-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 15px;
        pointer-events: none;
    }
    &__button {
        pointer-events: all;
        border: 0;
        background: none;
        cursor: pointer;
        font-size: 11px;
        font-family: "Bodoni 72 Oldstyle", sans-serif;
        color: $theme-primary;
    }
}
