@import 'src/styles/variables.scss';

.Container {
  display: flex;
  gap: 80px;
  position: relative;
  padding: 15px 118px;
  width: 100vw;
  height: 100%;
}

.ImageWrapper {
  display: flex;
  flex-direction: column;
  width: 50%;
  justify-content: center;
  align-items: flex-end;
  height: auto;

  img {
    max-width: 100%;
    max-height: 100%;
    height: auto;
    transition-duration: 0.6s;
    transition-property: all;
  }
}

.TextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  gap: 0;
}

.TextWrapper {
  max-width: 320px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  max-height: unset;
  height: calc(100% - 200px);

  p {
    font-family: $font-primary;
    color: $theme-primary;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 0;
  }

  .ContentWrapper {
    overflow-y: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: 200px;
    scrollbar-width: none;
    color: #fff;

    a {
      color: #fff;
    }

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }

  .mask {
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    position: absolute;
    height: 50px;
    opacity: 0.5;
    background: linear-gradient(
      180deg, rgba(255, 255, 255, 0) 0%, rgba(21,20,24,1) 100%);
    filter: blur(10px);
    pointer-events: none;
  }
}

.CreditInfo {
  font-family: $font-primary;
  color: $theme-primary;
  font-style: italic;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 0;
  max-width: 320px;
  margin-top: 15px;
}

@media (max-width: 1200px) {
  .Container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0;
    align-items: center;
    height: auto;
  }

  .TextContainer {
    justify-content: flex-start;
    gap: 10px;
  }

  .TextWrapper {
    height: auto;
    max-height: 110px;
    justify-content: flex-start;
  }

  .ImageContainer {
    justify-content: center;
  }

  .ImageWrapper {
    width: 100%;
    height: 350px;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .ImageContainer {
    justify-content: flex-start;
  }

  .ImageWrapper {
    width: 100%;
    height: 375px;
    align-items: center;
  }

  .TextContainer {
    width: 100%;
    justify-content: flex-start;
    gap: 10px;
  }

  .TextWrapper {
    height: calc(100% - 45px);
    max-height: 180px;
    justify-content: flex-start;

    .ContentWrapper {
      max-height: 130px;
    }
  }
}
