@import 'src/styles/variables.scss';

@media only screen and (max-width: 600px) {
  body {
    background: black;
    overflow: hidden;
  }
  #root {
    background: black;
  }
}
body {
  overflow: hidden;
}

.videoContainer {
  width: auto;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  @media only screen and (max-width: 600px) {
    height: 100%;
  }

  & video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    top: 0;
    left: 0;
    @media only screen and (max-width: 600px) {
      object-fit: contain;
    }

    &.showVideo {
      opacity: 1;
    }
  }
}

.setBackgroundImage {
  background: url('./assets/desktop_first_poster.jpg') no-repeat center;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;

  &.second {
    background-image: url('./assets/desktop_second_poster.jpg');
  }
  &.final {
    background-image: url('./assets/desktop_final_frame.jpg');
  }
}

.roomContainer {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  font-size: 3vw;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  background: radial-gradient(30.6% 12.6% at 50% 50%, rgba(18, 18, 50, 0.69) -50%, rgba(31, 31, 31, 6.9e-5) 88%);
}

.showRoom {
  opacity: 1;
  transition: opacity 1.5s;
}

.formContainer {
  font-family: 'Bodoni 72 Oldstyle', sans-serif;
  position: absolute;
  display: block;
  top: 50%;
  left: 20%;
  width: 100%;
  max-width: 420px;
  max-height: 100%;
  overflow: auto;
  transform: translateY(-50%);
  background: radial-gradient(46.6% 58.6% at 50% 50%, rgba(18, 18, 50, 0.69) 7%, rgba(31, 31, 31, 6.9e-5) 100%);
}

.hideButton {
  opacity: 0;
  z-index: -1;
  transition: opacity 0.8s, z-index 1s;
}

.welcomeText {
  color: #fff;
  font-size: 4vw;
}

@media only screen and (max-width: 600px) {
  .setBackgroundImage {
    background: url('./assets/mobile_first_poster.jpg') center center;
    background-size: contain;
    background-repeat: no-repeat;

    &.second {
      background-image: url('./assets/mobile_second_poster.jpg');
    }
    &.final {
      background-image: url('./assets/mobile_final_frame.jpg');
    }
  }

  .roomContainer {
    font-size: 7vw;
    background: radial-gradient(66.6% 10.6% at 50% 50%, rgba(18, 18, 50, 0.69) 0%, rgba(31, 31, 31, 6.9e-5) 85%);
  }

  .formContainer {
    max-width: 100%;
    left: auto;
    padding: 20px;
    top: 55%;
    background: radial-gradient(42.6% 42.6% at 50% 50%, rgba(18, 18, 50, 0.69) 0%, rgba(31, 31, 31, 6.9e-5) 100%);
  }
  .welcomeText {
    color: #fff;
    font-size: 7vw;
  }
}
.midVidContainer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #0000007d;
  opacity: 0;
  transition: all 0.8s ease;
  display: flex;
  justify-content: center;
  align-items: center;

  .vidPlayerWrapper {
    width: 60%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media only screen and (max-width: 600px) {
      width: 90%;
    }

    .videoWrapper {
      // height: 50vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;

      video {
        max-height: 100%;
        max-width: 100%;
      }

      .AddContainer {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
}
.showMidVid {
  opacity: 1;
}

.ControlsWrapper {
  display: flex;
  flex-direction: row;
  margin: 30px 0;
  width: 220px;
  justify-content: center;
  align-items: center;
  transition-duration: 0.6s;
  transition-property: opacity;

  .IconBox {
    width: 18px;
    height: 12px;
    cursor: pointer;

    svg {
      path {
        transition-duration: 0.6s;
        transition-property: all;
      }
    }

    &:hover,
    &.active {
      svg {
        path {
          fill: $theme-primary;
        }
      }
    }
  }

  .Timeline {
    margin: 0 12px;
    height: 1px;
    width: 100%;
    background: $timeline;
    position: relative;

    span {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      background-color: $theme-primary;
      z-index: 1;
      transition-duration: 0.2s;
      transition-property: all;
    }
  }

  .CloseButton {
    font-family: $font-primary;
    color: $theme-primary;
    font-style: italic;
    font-size: 12px;
    line-height: 26px;
    margin: 0 10px;
    cursor: pointer;
  }
}

.ControlsWrapperDisabled {
  pointer-events: none;
  opacity: 0.4;
}
