.Container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  padding: 15px 100px;
  width: 100vw;
  height: 100%;
  @media (max-width: 1200px) {
    padding: 0;
    height: auto;
  }
  @media (max-width: 768px) {
    justify-content: flex-start;
  }
}
