@import '../../../styles/variables.scss';

.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    font-family: $font-primary;
    font-style: normal;

    &__header {
        margin: 0;
        font-size: 18px;
        font-weight: bold;
        line-height: 26px;
        color: $theme-primary;
        text-align: center;
    }

    &__divider {
        width: 100%;
        height: 1px;
        margin-top: 16px;
        background-color: $divider;
    }
    &__description {
        margin: 10px 0;
        font-size: 11px;
        font-weight: 400;
        line-height: 15px;
        color: $theme-primary;
        text-align: center;
    }
}
