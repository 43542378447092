@import '../MainLayout.module.scss';

.header {
  height: 100px;
  top: 0;
  left: 0;
  z-index: 10;
  display: grid;
  grid-template-columns: 100px 1fr 70px;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, rgba(43, 43, 43, 0.3) 0%, rgba(51, 51, 51, 0.0001) 100%);
}
.logoContainer {
  width: 120px;
  height: 120px;
  position: relative;
  top: 0;
  left: 0;
}
.logo {
  height: auto;
  max-width: 68px;
  margin: 0 20px;
  pointer-events: all;
  & .image {
    width: 68px;
    height: 60px;
    display: block;
  }
}

.roomName {
  font-size: 20px;
  line-height: 26px;
  font-family: $font-primary;
  color: $theme-primary;
  letter-spacing: 6px;
  font-weight: normal;
  text-transform: uppercase;
  transition-property: opacity;
  transition-duration: 0.3s;
  opacity: 1;
  text-align: center;
  margin-top: -25px;
  &.invisible {
    opacity: 0;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  strong {
    display: flex;
  }

  .letter {
    margin: 0;
    animation-name: animateOpacity;
    animation-duration: 600ms;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }

  span {
    margin-left: 10px;
    cursor: pointer;
    display: flex;
    transition-duration: 0.3s;
    transition-property: all;
    opacity: 0;
    animation: animateOpacity 2s linear 3s forwards;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none;
  }
}

@media only screen and (max-width: 600px) {
  .header {
    height: 80px;
    width: 100%;
    text-align: center;
  }
  .logo {
    height: 57px;
    width: 64px;
    margin: auto auto auto 15px;
    & .image {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .roomName {
    margin-top: -25px;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 3px;

    span {
      transform: scale(0.8);
    }
  }
}

@media only screen and (max-width: 375px) {
  .roomName.withIcon {
    flex-direction: column;
    padding-top: 30px;

    span {
      margin: 0;
    }
  }
}

@keyframes animateRoomName {
  0% {
    transform: translateY(-200px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes animateOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
