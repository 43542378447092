.bookmarks {
  width: 100%;

  &__button {
    margin-top: 20px;
  }
}

.bookmark {
  display: block;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 15px;
}
