@import 'src/styles/variables';

.chat {
  position: absolute;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 29.69%, rgba(0, 0, 0, 0.8) 75.52%, rgba(0, 0, 0, 0) 100%);
  width: 220px;
  top: 80px;
  right: 0;
  bottom: 90px;
  z-index: 9999;

  &-title {
    display: block;
    padding: 1rem;

    &__text {
      text-transform: uppercase;
      text-align: center;
      font-weight: bold;
      font-size: 1.2rem;
    }
  }

  &-list {
    flex: 1;
    overflow-x: auto;
  }

  &-message {
    margin-bottom: .25rem;
    padding: 1.25rem;
    color: $text-white;

    &__highlighted {
      background: $text-white;
      color: $theme-secondary;
    }

    &.mentioned {
      color: $text;
      background-color: #cce5ff;
    }

    &__highlighted,
    &.mentioned {
      a,
      a:hover {
        color: #000;
      }
    }

    &__meta {
      display: flex;
      margin-bottom: .25rem;
    }

    &__author {
      font-size: .75rem;
      font-style: italic;
      padding-right: 4px;
    }

    &__data {
      display: none;
      font-size: 1rem;
      color: $text-white;
    }

    &__btn-delete {
      margin-left: auto;
    }

    &__body {
      font-size: .75rem;
      line-height: 1rem;
      word-break: break-all;

      a,
      a:hover {
        color: #fff;
        text-decoration: underline;
        cursor: pointer;
      }
    }

  }

  &-badge {
    background: #ffad46;
    color: #fff;
    border-radius: .25rem;
    font-weight: normal;
    font-size: .6rem;
    padding: .1rem;
  }

  &-form {
    margin-top: auto;
    padding: .25rem;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    display: flex;
    align-items: center;

    &__input {
      flex: 1;
      color: $text-white;
      background: transparent;
      border: none;
      padding: .5rem;
    }

    &__highlight {
      margin-right: .25rem;

      svg > path {
        fill: #fff;
      }

      &-icon {
        display: block;
        width: 20px;
        height: 20px;
      }

      > input {
        display: none;

        &:checked ~ svg > path {
          fill: #ffc108;
        }
      }
    }

    &__login {
      display: block;
      width: 100%;
      padding: .25rem;
      background: transparent;
      border: 0;
      font-family: inherit;
      color: $text;
      text-decoration: none;
      text-align: center;
    }
  }
}
