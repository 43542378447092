.Container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  position: relative;
  height: 100%;
}

.Image {
  width: 50%;
  height: auto;
  object-fit: contain;
}

.InfoWrapper {
  width: 50%;
  padding: 30px;
  color: white;
}

.Info {
  margin-bottom: 15px;
}
