@import '../MainLayout.module.scss';
@import '../../../styles/variables.scss';

.footer {
    @include contentPadding;
    position: fixed;
    bottom: 20px;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    height: auto;
    width: 100%;
    // max-width: 215px;
    z-index: 10;
    padding: 0 20px;
    @media (max-width: 500px) {
        font-size: 0.8em;
    }

    a {
        color: $border;
        text-decoration: underline;
        font-size: 1em;
        font-family: $font-primary;
    }
    button {
        color: $border;
        text-decoration: underline;
        font-size: 1em;
        font-family: $font-primary;
        appearance: none;
        background: none;
        border: none;
        cursor: pointer;
        font-family: inherit;
        padding: 0;
        text-align: inherit;
    }

    .menuContainer {
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: row;

        .soundIconWrapper {
          display: flex;
          cursor: pointer;

          svg {
            opacity: 0.4;
            cursor: pointer;
          }
        }

        button {
            margin-left: 12px;
        }
    }
    .dotNav {
        width: 130px;
        flex-direction: column;
        position: absolute;
        bottom: 30px;
        right: 5px;
        display: flex;
        background: rgba(0,0,0, 0.5);
        padding: 10px;
        border-radius: 10px;
        border-bottom-right-radius: 0;

        &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            right: 0;
            bottom: -5px;
            border-style: solid;
            border-width: 0 15px 5px 0;
            border-color: transparent rgba(0,0,0, 0.5) transparent transparent;
        }
        @media (max-width: 500px) {
            position: absolute;
            bottom: -19px;
            right: 21px;
        }
        .navItem {
            margin: 5px 0;
            color: white;
            font-size: 12px;
            text-decoration: none;
            position: relative;
            &::after {
              content: '';
              height: 1px;
              left: 0;
              bottom: 0px;
              background: $theme-primary;
              display: block;
              width: 0%;
              transition-property: width;
              transition-duration: 0.3s;
            }
        
            &:hover {
                &::after {
                    width: 100%;
                }
            }
            @media (max-width: 500px) {
                margin: 3px 0;
            }
        }
    }

    .dotIcon {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 3;
        width: 15px;
        height: 15px;
        transition: opacity 0.3s;
        margin-left: auto;

        appearance: none;
        background: none;
        border: none;
        cursor: pointer;
        font-family: inherit;
        padding: 0;
        opacity: 0.4;

        svg {
            width: 100%;
            pointer-events: none;
        }
    }

    .legal {
        font-size: 10px;
        font-family: helvetica, sans-serif;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.4);
        @media only screen and (max-width: 600px) {
            font-size: 9px;
        }
    }

    .legal span {
        font-weight: 600;
        text-transform: lowercase;
    }
}

@media only screen and (max-width: 600px) {
    .footer {
        align-items: center;
        max-width: 100%;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 60px;
        background-color: black;
        padding: 10px 20px;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.4);
    }
    .legal span {
        text-transform: lowercase;
    }
}
