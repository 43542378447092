@import '../../../styles/variables.scss';

.field {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    color: white;

    &__label {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex-wrap: wrap;
        position: relative;
        margin-left: 4px;
        color: white;
        top: 10px;

        &-text {
            font-size: 11px;
            font-weight: 500;
            line-height: 19px;
            font-style: italic;
        }
    }
}
