@import '../../../styles/variables.scss';

@mixin border($color) {
    // border: 1px solid $color;
}

.input {
    height: 40px;
    width: 100%;
    padding: 20px 25px;
    border: none;
    color: $theme-primary;
    border-bottom: 1px solid white;
    background: none;
    box-sizing: border-box;
    font-family: $font-primary;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;

    &:focus {
        outline: 0;
        // box-shadow: 0 0 0 1px black, 0 0 14px rgba(black, .2);
        // @include border(black);
    }
    &::placeholder {
        color: white;
    }

    &--error {
        @include border($error);
    }
    &--correct {
        @include border($success);
    }
}
