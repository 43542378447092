@import '../../../styles/variables.scss';

.list {
  padding-left: 20px;
  margin-bottom: 18px;
}
.list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3px;
  a {
    color: $theme-primary;
    font-family: $font-primary;
    font-size: 12px;
    line-height: 16px;
    text-decoration: none;
    position: relative;

    &::after {
      content: '';
      height: 1px;
      left: 0;
      bottom: 0px;
      background: $theme-primary;
      display: block;
      width: 0%;
      transition-property: width;
      transition-duration: 0.3s;
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }
  }

  &.active {
    a::after {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 897px) {
  .list {
    padding-left: 0;

    .list-item {
      align-items: center;
      justify-content: center;
      a {
        margin: 0 auto;
      }
    }
  }
}
