@import '../../../styles/variables.scss';

.nav {
  position: fixed;
  z-index: 11;
  width: 100%;
  height: 100%;
  padding: 30px 22px 20px 20px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 100%);
  transform: translateX(100%);
  transition: all 0.2s;
  top: 0;
  right: 0;
  bottom: 0;

  .heading {
    font-size: 14px;
    line-height: 15px;
    color: $theme-primary;
    font-family: $font-primary;
    font-weight: normal;
    margin: 0 0 10px;
    letter-spacing: 6px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none;
              
    span {
      text-transform: lowercase;
    }

    svg {
      transition-duration: 0.6s;
      transition-property: all;
      transform: rotate(-90deg) scale(0.5);
    }

    &.open {
      svg {
        transform: rotate(90deg) scale(0.5);
      }
    }
  }

  .legalLinksList {
    li {
      font-size: 14px;
      line-height: 15px;
      color: $theme-primary;
      font-family: $font-primary;
      font-weight: normal;
      margin: 0 0 5px;
      letter-spacing: 6px;
      text-transform: uppercase;
    }
  }

  @media (min-width: 897px) {
    width: $nav-width;
    height: auto;
  }

  &--active {
    transform: translateX(0);
  }

  @media only screen and (max-width: 897px) {
    padding: 15px 22px 20px 20px;
    text-align: center;

    .heading {
      text-align: center;
      font-size: 16.5px;
      line-height: 26px;
      letter-spacing: 3px;
      justify-content: center;
    }
  }
}
