@import '../../../styles/variables.scss';

.root {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem;
}

.spinner {
    border-style: solid;
    border-color: $spinner-secondary;
    border-top-style: solid;
    border-top-color: $spinner-primary;
    border-radius: 50%;
    animation: spin linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
