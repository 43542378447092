@import '../../styles/variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

@mixin contentPadding {
    // padding: 2.5vw;
    @media (max-width: 1000px) {
        // padding: 2em;
    }
    @media (max-width: 500px) {
        // padding: 15px;
    }
}

.Body {
    background: $theme-secondary;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    font-family: $font-primary;
    overflow: hidden;
    touch-action: none;
}

.Content {
    @include contentPadding;
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-grow: 1;
}
