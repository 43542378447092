.room {
  flex: 1;
  align-self: stretch;

  &__container,
  &__css-renderer {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
  }

  &__css-renderer {
    pointer-events: none;
  }
}

.nft {
  user-select: none;
}

.Backdrop {
  z-index: 10;
}
.Modal {
  // width: 50%;
  height: 100%;
  margin: 0 auto;
  background: none;
  box-shadow: none;;
  padding: 2rem 1rem;
  & > div:first-of-type {
    height: 100%;
    display: block;
  }
}
