@import 'src/styles/variables.scss';

.Container {
  display: flex;
  gap: 80px;
  position: relative;
  padding: 15px 118px;
  width: 100vw;
  height: 100%;
  justify-content: center;
}

.TextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50vw;
  gap: 0;
  height: 100%;
}

.TextWrapper {
  max-width: 800px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  max-height: unset;
  height: calc(100% - 130px);
  margin-bottom: 200px;

  p {
    font-family: $font-primary;
    color: $theme-primary;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    margin-bottom: 0;
  }

  .ContentWrapper {
    overflow-y: auto;
    height: 100%;
    display: flex;
    max-height: 50vh;
    align-items: flex-end;
    scrollbar-width: none;

    a {
      color: #fff;
    }

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }

  .mask {
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    position: absolute;
    height: 50px;
    opacity: 0.5;
    background: linear-gradient(
      180deg, rgba(255, 255, 255, 0) 0%, rgba(21,20,24,1) 100%);
    filter: blur(10px);
  }
}

@media (max-width: 1200px) {
  .TextWrapper {
    justify-content: flex-end;
    margin-bottom: 200px;

    .ContentWrapper {
      align-items: flex-start;
    }
  }
}

@media only screen and (max-width: 768px) {
  .Container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0;
    align-items: center;
    justify-content: center;
    height: auto;
  }

  .TextContainer {
    width: 100%;
    gap: 10px;
    justify-content: space-between;
  }

  .TextWrapper {
    height: calc(100% - 45px);
    justify-content: flex-end;
    margin-bottom: 100px;

    .ContentWrapper {
      align-items: flex-start;
    }
  }
}
