@import '../../../styles/variables.scss';

.forward {
    margin-top: 15px;
    margin-bottom: 35px;
    display: flex;
    flex-direction: column;

    &--centered {
        text-align: center;
    }

    &__description {
        display: inline-block;
        color: $theme-primary;
        margin-bottom: 10px;
        font-family: $font-primary;
        font-size: 11px;
    }

    &__link {
        color: $theme-primary;
        font-weight: 700;
        text-decoration: none;
        border: none;
        background: none;
        font-size: 11px;
        font-family: $font-primary;
        cursor: pointer;
        padding: 4px;
    }
}
