@import '../../../styles/variables.scss';

.container {
    &__text {
        color: $text;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        margin-top: 20px;
    }
}
