@import '../../../styles/variables.scss';

.error {
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 11px;
    // font-weight: bold;
    color: $error;
    line-height: 15px;
}
