@import '../../../styles/variables.scss';

.register-form {

  &__submit {
    margin-top: 15px;
  }

  &__label {
    display: flex;

    > label {
      margin-top: 8px;
      margin-right: 15px;
    }
  }
}
