@import 'src/styles/variables.scss';

.Container {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 15px 118px;
  width: 100vw;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .VideoWrapper {
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    video {
      max-height: 100%;
      max-width: 100%;
    }

    .AddContainer {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  .ControlsWrapper {
    display: flex;
    flex-direction: row;
    margin: 30px 0;
    width: 220px;
    justify-content: center;
    align-items: center;
    transition-duration: 0.6s;
    transition-property: opacity;

    .IconBox {
      width: 18px;
      height: 12px;
      // display: flex;
      cursor: pointer;

      svg {
        path {
          transition-duration: 0.6s;
          transition-property: all;
        }
      }

      &:hover, &.active {
        svg {
          path {
            fill: $theme-primary;
          }
        }
      }
    }

    .Timeline {
      margin: 0 12px;
      height: 1px;
      width: 100%;
      background: $timeline;
      position: relative;

      span {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        background-color: $theme-primary;
        z-index: 1;
        transition-duration: 0.2s;
        transition-property: all;
      }
    }

    .CloseButton {
      font-family: $font-primary;
      color: $theme-primary;
      font-style: italic;
      font-size: 12px;
      line-height: 26px;
      margin: 0 10px;
      cursor: pointer;
    }
  }

  .ControlsWrapperDisabled {
    pointer-events: none;
    opacity: 0.4;
  }
}

@media (max-width: 1200px) {
  .Container {
    display: flex;
    flex-direction: column;
    padding: 0;
    align-items: center;
    justify-content: center;
    height: auto;
  }
}

@media (max-width: 768px) {
  .Container {
    justify-content: flex-start;
  }

  .Wrapper {
    .VideoWrapper {
      height: auto;
    }
  }
}
